import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
// ColorMode,
import {  NetworkType } from "@airgap/beacon-sdk";
import { CONTRACT_ADDRESS } from "@/utils";
import BigNumber from "bignumber.js";

// Set the network
// const network = { type: NetworkType.ITHACANET };

// const network = { type: NetworkType.GHOSTNET };

const network = { type: NetworkType.MAINNET };

// initialiize the SDK
// const Tezos = new TezosToolkit("https://ithacanet.ecadinfra.com");

// const Tezos = new TezosToolkit("https://rpc.ghostnet.teztnets.xyz");

// const Tezos = new TezosToolkit("https://ghostnet.tezos.marigold.dev");
// MAINNET // 
const Tezos = new TezosToolkit("https://mainnet.tezos.marigold.dev/");

const wallet = new BeaconWallet({
  name: "DIYFRAME",
  iconUrl: 'https://contract.diyframe.xyz/favicon.svg',
  preferredNetwork: network.type,
}); // Takes the same arguments as the DAppClient constructor

Tezos.setWalletProvider(wallet);

// setting the color mode for beacon wallet
// wallet.client.setColorMode(ColorMode.DARK);

const getContract = async () => await Tezos.wallet.at(CONTRACT_ADDRESS);

export default {
  /**
   * @description requests permission to connect to the network
   */
  async connectWallet({ dispatch }) {
    try {
      await wallet.requestPermissions({
        network: network,
      });
      dispatch("checkWalletConnection");
    } catch (error) {
      console.log(error);
    }
  },
// FÜR /FRAME ,tezosWalletAddress
  async connectionToContract({ commit }) {
    console.log("🤝 action.js / connectionToContract()");
   let pkh = "tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v"
   // tezosWalletAddress; //"tz1i4vowP9mHi83KTJikHwB433id3CXGasqU"
     // let pkh = "tz1i4vowP9mHi83KTJikHwB433id3CXGasqU";

    commit("updatePkh", pkh);
    commit("updateConnected", true);
  },
// ??
  async OFFgetMemoListFrame({ commit }, frameID) {
    commit("updateLoading", true);
    
   //  try {
    //   let active_memo = [];
     //  let done_memo = [];

   // if (state.connected) {
  
   console.log("frameID",frameID);
        const contract = await getContract();
        const storage = await contract.storage();

       // console.log("----", JSON.stringify(storage, null, '    '))

      
      

       // console.log("contract",contract);
        // 
        console.log("storage", storage.memos.valueMap);
       
      /*
       
        const storage_user_memos = await storage.users.get(state.pkh);

        const user_memos = storage_user_memos.map((val) => new BigNumber(val).toNumber());

        console.log("user_memos: ",user_memos);
       
        for (let index = 0; index < user_memos.length; index++) {
          const memo = await storage.memos.get({
            owner: 'tz1ZEoCxEKW6fPZv8LZrpu2N6DBZcCCqMS6v',
            id: frameID // user_memos[index].toString(),
          });
         
          const formated_memo = { ...memo, id: new BigNumber(memo.id).toString() };

          if (!memo.done) {
            active_memo.push(formated_memo);
          } else {
            done_memo.push(formated_memo);
          }

      }

      commit("updateActiveMemo", active_memo);
      commit("updateDoneMemo", done_memo);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        commit("updateLoading", false);
      }, 1000);
    } 
     */
  },

  
  /**
   * @description Check if the wallet is connected, updates the pkh in state if connected
   */
  async checkWalletConnection({ commit }) {
    try {
      const activeAccount = await wallet.client.getActiveAccount();
      let pkh;

      if (activeAccount) {
        // If defined, the user is connected to a wallet.
        pkh = activeAccount.address;
        commit("updatePkh", pkh);
        commit("updateConnected", true);
      } else {
        commit("updatePkh", "");
        commit("updateConnected", false);
      }
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * @description disconnects the wallet connection to the dApp
   */
  async disconnectWallet({ dispatch }) {
    await wallet.clearActiveAccount();
    dispatch("checkWalletConnection");

    // set the pkh to empty
  },

  /**
   * @description Gets a list of a users memos from the contract and updates the state TODO check if pkg addres
   */
  async getMemoListFrame({ state, commit }) {
    console.log("getMemoList() fffframmmmee");
  //  commit("updateLoading", true);

    try {
      let active_memo = [];
      let done_memo = [];

      console.log("((state",state);
     // if (state.connected) {
  
        const contract = await getContract();
        const storage = await contract.storage();
        const storage_user_memos = await storage.users.get("tz29KCrBcS6aJhqwPmHB5Cyh2cccjTs9Lghx");

        const user_memos = storage_user_memos.map((val) => new BigNumber(val).toNumber());
       // console.log("_user_memos", user_memos);

        // dann nummer


        // memos_key

        for (let index = 0; index < user_memos.length; index++) {

          const memo = await storage.memos.get({
            owner: "tz29KCrBcS6aJhqwPmHB5Cyh2cccjTs9Lghx",
            id: user_memos[index].toString(),

          });

          const formated_memo = { ...memo, id: new BigNumber(16).toString() };

          console.log("__formated_memo",formated_memo);
          console.log("__formated_memo",formated_memo.owner);





          if (!memo.done) {
            active_memo.push(formated_memo);
          } else {
            done_memo.push(formated_memo);
          }
      //  }
      }

      console.log("TODO: convertMemoIDS hier",);
      
     // commit("updateActiveMemo", active_memo);
     // commit("updateDoneMemo", done_memo);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        commit("updateLoading", false);
      }, 1000);
    } // end try catch
  },
  /**
   * @description Gets a list of a users memos from the contract and updates the state without loading the page
   */
  async softUpdateMemoList({ state, commit }) {
    try {
      let active_memo = [];
      let done_memo = [];

      if (state.connected) {
  
        const contract = await getContract();
        const storage = await contract.storage();
        const storage_user_memos = await storage.users.get(state.pkh);

        const user_memos = storage_user_memos.map((val) => new BigNumber(val).toNumber());
        console.log("_softUpdateMemoList / _user_memos", user_memos);

        for (let index = 0; index < user_memos.length; index++) {
          const memo = await storage.memos.get({
            owner: state.pkh,
            id: user_memos[index].toString(),
          });

          const formated_memo = { ...memo, id: new BigNumber(memo.id).toString() };

          if (!memo.done) {
            active_memo.push(formated_memo);
          } else {
            done_memo.push(formated_memo);
          }
        }
      }

      commit("updateActiveMemo", active_memo);
      commit("updateDoneMemo", done_memo);
    } catch (error) {
      console.log(error);
    } 
  },
  /**
   * @param {string} memo
   * @description Creates a new memo and adds it to the contract storage
   */
  async addMemo({ dispatch }, memo) {
    try {
      const contract = await getContract();
      const op = await contract.methods.addMemo(memo).send();
      await op.confirmation()
      dispatch("softUpdateMemoList");
    } catch (error) {
      console.log(error);
    }
  },

    /**
   * @param {string} id
   * @description deletes the memo with the given id from the contract storage
   */
  async deleteMemo({ dispatch }, id) {
    try {
      const contract = await getContract();
      const op = await contract.methods.deleteMemo(id).send();
      await op.confirmation()
      dispatch("softUpdateMemoList");
    } catch (error) {
      console.log(error);
    }
  },

   /**
   * @param {string} id
   * @description toggles the done field for the given id from the contract storage
   */
   async getIDowner(shortID) {
    try {
      const contract = await getContract();

// console.log("contract",contract);

const storage = await contract.storage();

// console.log("storage", storage.memos.valueMap);
// console.log("xxxxxxxxxxx ", storage.memos.valueMap.size);
// console.log("11111 ", storage.memos.valueMap.get());

let stored = storage.memos.valueMap
//  stored.get('id')
// console.log("stored.get('id')", stored.get(1));

console.log("shortID ",shortID);

for (let [key, value] of stored) {
  console.log(key + " = " + value);
  
  if(shortID === key) {
    console.log("REDIRECT----");
  }

  }

// console.log("xxxxxxxxxxx", storage.memos);
      // dispatch("softUpdateMemoList");
    } catch (error) {
      console.log(error);
    }
  },


  /**
   * @param {string} id
   * @description toggles the done field for the given id from the contract storage
   */
  async toggleMemoStatus({ commit }, shortID) {
    try {
      const contract = await getContract();

// 
console.log("toggleMemoStatus");

const storage = await contract.storage();


// console.log("storage", storage.memos.valueMap);
// console.log("xxxxxxxxxxx ", storage.memos.valueMap.size);
// console.log("11111 ", storage.memos.valueMap.get());

let map = storage.frames.valueMap
//  stored.get('id')
// conso


// console.log("MAP ", storage.memos.valueMap.keys);

//
 console.log("shortID ",shortID);


// for (let [key,value] of stored) {

// GEHT:
map.forEach(function(key) {
	// console.log(key);

  // 
  console.log("key.id: ", key.id.c[0]);
  // console.log("key.owner: ", key.owner);

  if(Number(shortID) === key.id.c[0]) {
    console.log("+++++++++++++++++++++ REDIRECT----",key.owner);
    let val = "https://alpha.diyframe.xyz/frame/"+key.owner+"/"+key.id.c[0]
    window.open(val, '_self')
  }

})







  // kann raus
  commit("updateConnected", true);

 

// console.log("xxxxxxxxxxx", storage.memos);
      // dispatch("softUpdateMemoList");
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * @param {string} id
   * @description updates the memo with the given id from the contract storage
   */
  async updateMemo({ dispatch }, payload) {
    try {
      const contract = await getContract();
      const op = await contract.methodsObject.updateMemo(payload).send();
      await op.confirmation()
      dispatch("softUpdateMemoList");
      console.log("action.js / dispatch softUpdateMemoList");
    } catch (error) {
      console.log(error);
    }
  },
};
