<template>
  <v-app id="inspire" class="black">
<v-card flat tile color="black" height="100vh">
  <v-card-title primary-title class="grey--text">
    DIYFRAME.XYZ<br/>REDIRECTING TO FRAME...<br/><br/>
    to.diyframe.xyz/{{ this.$route.params.id }}
  </v-card-title>
</v-card>
  </v-app>
</template>

<script>
// #import #b
/* eslint-disable */
import { mapActions, mapGetters } from "vuex";

// #data #r
export default {
  
   data() {
    return {
      cycleInterval: 10000,
    }},
  computed: {
    ...mapGetters(["getActiveMemo", "getDoneMemo", "getLoading","getConnected","getPkh"]),
  },
  // #ted
    mounted() {

 //  
 console.log("this.$route. ",this.$route);

if(this.$route.params.id === undefined) {
console.log("NO ID");
}

 this.toggleMemoStatus(this.$route.params.id)  
  },
  

  // #m
  methods: {
    ...mapActions(["connectionToContract","getMemoListFrame","checkWalletConnection", "getMemoList","addMemo", "getIDowner","toggleMemo","deleteMemo", "toggleMemoStatus","updateMemo","connectWallet", "disconnectWallet"]),
  },
};
</script>
